<template>
  <div
    ref="content"
    class="button_fly"
  >
    <div
      class="flex align-center sulguni pointer mb-7 rocky--text"
      @click="linkClick()"
    >
      <r-icon
        class="mr-2"
        icon="arrow-left"
      />
      <div style="margin-top: -2px">
        Назад
      </div>
    </div>
    <loader v-if="isLoading" />
    <div v-else>
      <h1 class="ricotta">
        <!--  {{ itemsDef.title }}-->
        Новый объект
      </h1>
      <div>
        <div class="grid-cont mt-8">
          <div class="edit__form">
            <div class="flow">
              <!-- <div class="flow__update">
                <ImgUploader
                  class="organization-form__img-cont mr-6"
                  @change="onUpload"
                  @onViewImage="onViewImage"
                  @deleteFile="deleteFile"
                  :src="imageUrl ? `/cityProperty/rest/${imageUrl}` : null"
                />

              </div>-->
              <r-input
                label="Наименование"
                :error="!itemsDef.title"
                @keyup="validSave"
                v-model="itemsDef.title"
              />
              <r-select
                class="mt-6 flex-1"
                style="display: flow-root;"
                :items="objectTypes"
                label="Тип объекта"
                :value="itemsDef.type"
                @input="onChangeType"
              />
            </div>
            <r-input
              class="mt-6 flex-1"
              label="Собственник"
              v-model="itemsDef.owner"
            />

            <div class="mt-6 flex_area">
              <r-input
                class=" mr-2 flex-1"
                label="Площаль м²"
                :error="!itemsDef.areaS"
                @keyup="validSave"
                v-model="itemsDef.areaS"
              />
              <r-input
                class="ml-2 flex-1"
                label="Этаж"
                :error="!itemsDef.floorS"
                @keyup="validSave"
                v-model="itemsDef.floorS"
              />
            </div>
            <r-textarea
              class="mt-6"
              label="Состояние и описание"
              :rows="8"
              v-model="itemsDef.description"
            />
            <r-input
              class="mt-10 flex-1 flex_input"
              label="Телефон"
              v-model="itemsDef.phone"
              v-mask="'+#(###)###-####'"
              @keyup="validPhone"
            />
            <div class="mt-10 buttonPhone">
              <r-button-simple
                class="buttonPhone__cont"
                size="larishae"
                type="primary"
                color="rocky"
                :disabled="phone"
                @click="addPhone"
                icon="add"
              />
            </div>
            <div class="buttonPhoneContent">
              <div
                class="mt-4 buttonPhoneContent__icon"
                v-for="(item, index) in objectsPhone"
              >
                <r-icon
                  class="mr-2 mt-1 margin-right"
                  icon="telephone"
                  size="16"
                  fill="rocky"
                />
                {{ item }}
                <div
                  class="buttonPhoneContent__delete mt-1"
                  @click="deletePhone(index)"
                >
                  <r-icon
                    class="mr-3"
                    icon="delete"
                    fill="fargo"
                  />
                </div>
              </div>
            </div>
            <r-input
              class="mt-6 flex-1 emailValid"
              label="Имейл"
              v-model="itemsDef.email"
              @keyup="validEmail"
            />
            <div class="mt-6 emailAdd">
              <r-button-simple
                class="emailAdd__cont"
                size="larishae"
                type="primary"
                color="rocky"
                :disabled="email"
                @click="addEmail"
                icon="add"
              />
            </div>
            <div>
              <div class="mt-4 objectEmail">
                <div
                  class="mt-4 objectEmail__table flex"
                  v-for="(item, index) in objectsEmail"
                >
                  <div class="flex">
                    <r-icon
                      class="mr-2 mt-1 margin-right"
                      icon="mail"
                      fill="rocky"
                      size="16"
                    />
                    <div>{{ item }}</div>
                  </div>
                  <div
                    class="ml-auto mt-1 mr-10"
                    @click="deleteEmail(index)"
                  >
                    <r-icon
                      class="mr-3"
                      icon="delete"
                      fill="fargo"
                    />
                  </div>
                </div>
              </div>
            </div>

            <h2 class="taleggio mt-10 mb-5">
              Галерея изображений
            </h2>
            <upload-file-input
              :count-file="5"
              upload-url="/uploadfile/index.php"
              :value="itemsDef.images"
              @input-file="file($event, 'img')"
              :accept-file="['image/png', 'image/jpeg', 'image/gif']"
              @input="uploadFile($event, 'img')"
              sub-title="или перетяните сюда до 5 фотографий, общим объёмом не более 50 МБайт"
            />
          </div>

          <div class="edit__map">
            <rir-map
              :center="$cityCenter"
              collapse-btn
            >
              <div>
                <ymap-marker
                  :coords="$cityCenter"
                  :marker-id="`marker_1`"
                  :options="{
                    hideIconOnBalloonOpen: false,
                    draggable: true
                  }"
                  :icon="$markerIcon()"
                  @dragend="onDragMarker"
                  cluster-name="main"
                />
              </div>
            </rir-map>
            <div class="map_cursor">
              <div class="message">
                Чтобы изменить положение на карте, перетащите
                точку
              </div>
              <r-input
                class="mt-4 flex-1"
                label="Адрес"
                v-model="itemsDef.address"
                @blur="searchAddress(address)"
                @onPressEnter="searchAddress(address)"
              />
            </div>
          </div>
        </div>
        <div class="button_container mt-10">
          <r-button
            class="flex-1"
            :disabled="isSave"
            @click="Save"
            title="Сохранить"
            width="wide"
          />
        </div>
      </div>
      <save ref="childSave" />
      <delete-warn-modal ref="childDelete" />
      <r-modal
        ref="modal"
        close-icon
        fullscreen
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import RirMap from '../components/RirMap.vue';
import Save from '@/components/Save';
import ImgUploader from '../components/ImgUploader.vue';
import UploadFile from '@/components/dragUploadFile.vue';
import DeleteWarnModal from '@/components/DeleteWarnModal';
import ModalCrop from '@/components/ModalCrop';
import ModalViewImage from '@/components/ModalViewImage';
import PlaceApi from '../api/PlaceApi';
import { formattedFiles } from '../helpers/utils';
import Loader from '@/components/Loader.vue';
import UploadFileInput from '@/components/UploadFileInput.vue';

export default {
  components: { UploadFileInput, Loader, Save, ImgUploader, RirMap, UploadFile, DeleteWarnModal },
  data() {
    return {
      objectTypes: [
        { id: '0', title: '' },
        { id: '1', title: 'Муниципальные' },
        { id: '2', title: 'Коммерческие' }
      ],
      itemsDef: {
        address: null,
        title: null,
        owner: null,
        areaS: null,
        floorS: null,
        description: null,
        phone: null,
        type: 0,
        email: null,
        images: []
      },
      coordinates: [],
      isSave: true,
      img: null,
      item: {},
      email: true,
      phone: true,
      countEmail: 0,
      countPhone: 0,
      objectsEmail: [],
      objectsPhone: [],
      imageUrl: null,
      initialData: []
    };
  },
  computed: {
    // пред загрузка
    isLoading() {
      return this.$store.state.isObjectLoading;
    }
  },
  created() {
    // this.onLoadMarker();
    const v = this;
    v.itemsDef.title = '';
    v.itemsDef.address = '';
    v.coordinates[0] = 64.91185059862988;
    v.coordinates[1] = 77.78357681226097;
    v.itemsDef.owner = '';
    v.itemsDef.floorS = '';
    v.itemsDef.description = '';
    v.itemsDef.areaS = '';
    v.itemsDef.type = 0;
    v.objectsEmail = [];
    v.objectsPhone = [];
    v.img = null;
  },
  methods: {
    file(files, type) {
      this.itemsDef.images = [];
      this.imageUrl = null;
      this.formattedFiles(files, type);
    },
    formattedFiles(files, type) {
      const _this = this;
      files.forEach(el => {
        if (el?.files?.length) {
          const fileList = el.files.map(el => ({
            contentLength: Number(el.size),
            contentType: el.type,
            filename: el.name,
            icon: null,
            type: 'image/jpeg',
            name: el.name,
            url: `${el.url}`
          }));
          this.itemsDef.images.push(...fileList);
          this.imageUrl = this.itemsDef.images[0].url;
        } else {
          this.itemsDef.images.push(el);
        }
      });
    },
    uploadFile(files, type) {
      const _this = this;
      if (!files?.length) {
        this.itemsDef.images = [];
        return;
      }
      _this.formattedFiles(files, type);
    },
    deleteFile() {
      this.imageUrl = null;
    },
    onViewImage() {
      this.$refs.modal.openModal(ModalViewImage, {
        file: `${this.imageUrl}`
      });
    },
    onUpload(file, target = null) {
      // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
      if (this.loadedImg?.src) {
        URL.revokeObjectURL(this.loadedImg.src);
      }
      // 2. Create the blob link to the file to optimize performance:
      const blob = URL.createObjectURL(file);

      // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
      this.loadedImg = {
        src: blob,
        type: file.type
      };

      this.$refs.modal.openModal(ModalCrop, {
        image: this.loadedImg,
        valName: target === 'staff' ? 'contactImg' : 'imageUrl',
        portrait: target === 'staff'
      });
      this.isChange('loadedImg');
    },
    isChange(field) {
      this.isSave = true;
    },
    deleteModel() {
      const id = false;
      this.$refs.childDelete.deleteContainer(id);
    },
    deletePhone(index) {
      this.objectsPhone.splice(index, 1);
    },
    deleteEmail(index) {
      this.objectsEmail.splice(index, 1);
    },
    remove(arr, indexes) {
      const arrayOfIndexes = [].slice.call(arguments, 1);
      return arr.filter((item, index) => arrayOfIndexes.indexOf(index) == -1);
    },
    addPhone() {
      this.objectsPhone = [this.itemsDef.phone, ...this.objectsPhone];
      console.log(this.objectsPhone);
      this.itemsDef.phone = '';
      this.phone = true;
    },
    async onChangeType(type) {
      this.itemsDef.type = type;
    },
    addEmail() {
      this.objectsEmail = [this.itemsDef.email, ...this.objectsEmail];
      this.itemsDef.email = '';
      this.email = true;
    },
    validEmail() {
      const re = /\S+@\S+\.\S+/;
      if (re.test(this.itemsDef.email)) {
        this.email = false;
      } else {
        this.email = true;
      }
    },
    validPhone() {
      const re = /\+\d\(\d{3}\)\d{3}-\d{4}/;
      if (re.test(this.itemsDef.phone)) {
        this.phone = false;
      } else {
        this.phone = true;
      }
    },
    clickImg() {
      this.$refs.fileImg.fileImgDelite();
      this.img = null;
    },
    onChangeFiles(files) {
      if (!files?.length) {
        this.files = [];
        return;
      }

      const formattedFiles = [];

      files.forEach(el => {
        if (el?.files?.length) {
          const fileList = el.files.map(el => ({
            contentLength: Number(el.size),
            contentType: el.type,
            filename: el.name,
            // url: el.url
            url: `${el.url}`
          }));
          formattedFiles.push(...fileList);
        } else {
          formattedFiles.push(el);
        }
      });
      this.files = formattedFiles;
      this.$refs.fileImg.fileImg(formattedFiles[0].url);
      this.imageUrl = formattedFiles[0].url;
    },
    validSave() {
      const v = this;
      if (!v.itemsDef.title || !v.itemsDef.areaS || !v.itemsDef.floorS) {
        v.isSave = true;
      } else {
        v.isSave = false;
      }
    },
    Save() {
      const v = this;
      const info = 'new';
      const img = [];
      this.itemsDef.images.forEach(item => {
        if (item?.url) {
          img.push(item.url);
        }
      });
      const json = {
        address: v.itemsDef.address,
        areaS: v.itemsDef.areaS,
        description: v.itemsDef.description,
        email: JSON.stringify(v.objectsEmail),
        firm: v.itemsDef.owner,
        floorS: v.itemsDef.floorS,
        lat: v.coordinates[0] ? v.coordinates[0] : 47.5259125,
        lng: v.coordinates[1] ? v.coordinates[1] : 42.1899268,
        phone: JSON.stringify(v.objectsPhone),
        photo1: img[0] ? img[0] : '',
        photo2: img[1] ? img[1] : '',
        photo3: img[2] ? img[2] : '',
        images: img,
        title: v.itemsDef.title,
        type: v.itemsDef.type

      };
      this.$refs.childSave.uploadContainer(json, info);
    },
    linkClick() {
      const r = this.$router.resolve({
        name: 'index',
        params: {}
      });
      window.location.assign(r.href);
    },
    onDragMarker(e) {
      /*
      this.coordinates = e.get('target').geometry._bounds[0];
      this.searchAddress(this.coordinates); */
      const coords = e.originalEvent.target.geometry.getCoordinates();
      if (coords?.length === 2) {
        this.coordinates = coords;
        this.searchAddress(this.coordinates);
      }
    },
    async searchAddress(address) {
      if (!address) return;
      const api = new PlaceApi();

      const data = {};
      const isFindAddress = Array.isArray(address);
      console.log('isFindAddress', address);
      if (isFindAddress) {
        data.lat = address[0];
        data.lng = address[1];
      } else {
        data.address = this.itemsDef.address;
      }

      const res = await api.getAddress(JSON.stringify(data));

      if (isFindAddress && res?.address) {
        if (res?.addressFull?.street && res?.addressFull?.house) {
          this.itemsDef.address = `${res.addressFull.street} ${res.addressFull.house}`;
        } else {
          this.itemsDef.address = res.address;
        }
      } else if (!isFindAddress && res?.lat && res?.lng) {
        this.lat = res.lat;
        this.lng = res.lng;
        console.log('latlng', this.lat, this.lng);
        this.coordinates = [this.lat, this.lng];
      }
      this.isChange('address');
    }
  }
};
</script>

<style lang="scss" scoped>
.buttonPhoneContent {
  margin-top: 100px;

  &__icon {
    display: table;
    width: 100%;
  }

  &__delete {
    display:table;
    float: right;
    cursor: pointer;
    margin-right: 40px;
  }
}
.buttonPhone{
  display: table;
  float: right;
  &__cont {
    width: 40px;
  }
}
.flex_input{
  width: calc(100% - 50px);
  float: left;
}
.flex_area {
  display: flex;
}
.flow{
  display: flow-root;
  &__update {
    float: left;
    display: table;
  }
  &__img {
    margin-left: 8px;
    margin-top: 6px;
    position:absolute;
    display: table;
    color: #3D75E4;
    cursor: pointer;
  }
}
.pointer {
  cursor: pointer;
}
.double {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #E14761;
}

@mixin reset-list {
  font-style: normal;
  font-weight: normal;
  position: static;
}

.message {
  @include reset-list;
  width: 361px;
  height: 16px;
  left: 820px;
  top: 621px;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-top: 5px;
  opacity: 0.48;
}

.button_container {
  margin-top: 30px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #FFFFFF;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.grid-cont {
  grid-column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.edit {
  &__map {
    width: 650px;
    height: 380px;
  }
  @media(max-width: 1550px ) {
    &__map {
      width: 500px;
      height: 380px;
    }
  }
}

::v-deep .rir-map__legend {
  display: none;
}
.emailValid {
  width: calc(100% - 50px);
  float: left;
}

.emailAdd {
  display: table;
  float: right;
  &__cont {
    width: 40px;
  }
}
.objectEmail {
  display: table;
  width: 100%;
  &__table {
    width: 100%;
  }
  &__icon {
    display:table;
    float: right;
    cursor: pointer;
    margin-right: 40px;
  }
}
.map_cursor {
  display: table;
  width: 100%;
}

</style>
<style>
.fargo {
  background-color: unset;
}
</style>
