<template>
  <div>
    <r-uploader
      v-model="filesUpload"
      :title="title"
      :sub-title="subTitle"
      :accept="acceptFile"
      :callback="uploadFile"
      :max-files="countFile"
      :button-simple-option="{
        icon: 'delete',
        color: 'fargo'
      }"
    />
    <div
      class="mt-6 flex flex-direction-column"
    >
      <r-file
        v-for="(file, index) of value"
        :key="index"
        :file="file"
        :title="file.name"
        :icon-preview="file.icon == 'image' ? null : 'car'"
        class="mb-6"
        :button-simple-option="{
          icon: 'delete',
          color: 'fargo'
        }"
        @actions="deleteFile"
        @clickPreview="openModalGallery(file, index)"
      />
    </div>
    <r-modal
      ref="modal"
      close-icon
    />
  </div>
</template>

<script>
import ModalGallery from '@/components/ModalGallery.vue';
export default {
  name: 'UploadFileInput',
  components: {
  },
  props: {
    title: {
      type: String,
      default: 'Выбрать файлы'
    },
    icon: {
      type: String,
      default: 'attach'
    },
    iconFill: {
      type: String,
      default: 'rocky'
    },
    subTitle: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => []
    },
    countFile: {
      type: Number,
      default: 2
    },
    countLine: {
      type: Number,
      default: 2
    },
    listView: {
      type: String,
      default: 'DEFAULT'
    },
    showSize: {
      type: Boolean,
      default: true
    },
    acceptFile: {
      type: Array,
      default: () => ['.jpg', '.jpeg', '.png', '.gif']
    },
    uploadUrl: {
      type: String,
      require: true
    },
    paramsBody: {
      type: Object,
      default: () => ({})
    },
    multiple: {
      type: Boolean,
      default: false
    }

  },
  data() {
    return {
      filesUpload: [],
      filesLength: 0,
      isUpload: false,
      filesLoad: '',
      disabledUpload: false,
      dataLoad: null,
      fileSize: '',
      expandImg: false

    };
  },

  computed: {
    progress() {
      return ((this.filesCountUpload / this.filesLength) * 100).toFixed(3);
    }
  },
  methods: {
    async openModalGallery(el, index) {
      await this.$refs.modal.openModal(ModalGallery, {
        items: el,
        index
      });
    },
    expandPhoto() {
      this.expandImg = true;
    },
    deleteFile(file) {
      const files = this.value.filter(val => file.name !== val.name);
      this.$emit('input-file', files);
    },
    preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    highlight() {
      this.$refs.upload.classList.add('highlight');
    },
    unHighlight() {
      this.$refs.upload.classList.remove('highlight');
    },
    load(files) {
      this.filesLoad = files;
      if (files[0].size > 1024 * 1024) {
        const size = files[0].size / 1024 / 1024;
        this.fileSize = `${size.toFixed(1)} МБайт`;
      } else {
        const size = files[0].size / 1024;
        this.fileSize = `${size.toFixed(0)} КБайт`;
      }
      this.disabledUpload = true;
    },
    handleDrop(e) {
      const dt = e.dataTransfer;
      const { files } = dt;
      this.load(files);
    },
    async handleFiles(files) {
      if (this.filesLoad != '') {
        const filesList = this.filesLoad;

        if (filesList.length) this.isUpload = true;
        this.filesLength = filesList.length;
        await this.uploadFile();
      }
    },
    async uploadFile({ file }) {
      /* if (this.multiple) {
        for (const item of Array.from(file)) {
          const re = /(?:\.([^.]+))?$/;
          const fileExt = re.exec(item.name)[1];
          this.filesCountUpload += 1;

          if (!this.acceptFile.includes(`.${fileExt.toLowerCase()}`)) {
            if (this.progress >= 100) {
              this.isUpload = false;
              this.$refs.input.value = '';
            }
            return;
          }
        }
      } else {
        const re = /(?:\.([^.]+))?$/;
        const fileExt = re.exec(file.name)[1];
        this.filesCountUpload += 1;

        if (!this.acceptFile.includes(`.${fileExt.toLowerCase()}`)) {
          if (this.progress >= 100) {
            this.isUpload = false;
            this.$refs.input.value = '';
          }
          return;
        }
      } */
      console.log('uploadFile');
      return new Promise(resolve => {
        const url = this.uploadUrl;
        const formData = new FormData();
        console.log('uploadFile');
        if (this.multiple) {
          for (const item of Array.from(file)) {
            formData.append('files[]', item);
          }
        } else {
          formData.append('files[]', file);
          formData.append('filename', file.name);
        }
        // formData.append('userId', undefined);
        const params = Object.keys(this.paramsBody);
        if (params.length !== 0) {
          params.forEach(key => {
            formData.append(key, this.paramsBody[key]);
          });
        }
        fetch(url, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            ...this.paramsHeaders
          },
          body: formData
        })
          .then(async response => {
            if (response.status !== 200) {
              throw Error(`error load image - ${response.status}`);
            }
            /* this.filesUpload.push({
              type: file.type,
              size: file.size,
              name: file.name,
              url: URL.createObjectURL(file)
            }); */

            const reader = response.body.getReader();
            // const contentLength = +response.headers.get('Content-Length')
            let receivedLength = 0;
            const chunks = [];
            while (true) {
              const { done, value } = await reader.read();

              if (done) {
                break;
              }

              chunks.push(value);
              receivedLength += value.length;
            }
            const chunksAll = new Uint8Array(receivedLength);
            let position = 0;
            for (const chunk of chunks) {
              chunksAll.set(chunk, position);
              position += chunk.length;
            }
            const result = new TextDecoder('utf-8').decode(chunksAll);
            const files = JSON.parse(result);
            const filesValue = [files];
            this.$emit('input', filesValue);

            resolve();
          })
          .catch(error => {
            console.log('error', error);
          })
          .finally(() => {
            if (this.progress >= 100) {
              this.isUpload = false;
              this.$refs.input.value = '';
            }
            resolve();
          });
      });
    },

    removeFile() {
      this.disabledUpload = false;
      this.filesLoad = '';
      if (this.countLine == 1) {
        this.$refs.input.value = '';
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.files-delete {
  cursor: pointer;
  margin-left: 13px;
}

.files {
  margin-top: 24px;
  color: #04153E;
  margin-bottom: 10px;
  height: 48px;
  border-radius: 8px;
  background: rgba(#3D75E4, 0.08);
  display: flex;
  align-items: center;
}

.files_icon {
  height: 100%;
  width: 76px;
  border-radius: 8px;
  background: #E4EDFB;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.files-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.files-body {
  height: 100%;
  width: calc(100% - 76px);
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
