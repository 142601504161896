<template>
  <div class="crop-modal">
    <cropper
      v-if="portrait"
      :src="image.src"
      :stencil-component="$options.components.CircleStencil"
      ref="cropper"
    />

    <cropper
      v-else
      :src="image.src"
      :stencil-props="{
        aspectRatio: 16 / 10
      }"
      ref="cropper"
    />

    <p class="mt-6 feta opacity-72">
      {{ tip }}
    </p>
    <r-button
      class="mt-10"
      color="secondary"
      @click="cropImage"
    >
      Сохранить
    </r-button>
  </div>
</template>

<script>
import { CircleStencil, Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  components: {
    Cropper,
    CircleStencil
  },
  props: {
    image: {
      type: Object,
      required: true
    },
    valName: {
      type: String
    },
    portrait: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    tip() {
      const word = this.portrait ? 'лицо' : 'объект';
      const text = `Скадрируйте снимок так, чтобы было хорошо видно ${word}`;
      return text;
    }
  },
  methods: {
    cropImage() {
      const result = this.$refs.cropper.getResult();

      // Конвертация из canvas в Blob
      result.canvas.toBlob(blob => {
        const formData = new FormData();
        console.log('blobType', blob.type);

        // Создание объекта файла
        const file = new File([blob], 'filename.jpeg', {
          lastModified: new Date().getTime(),
          type: blob.type
        });

        // Заголовки formDat'ы
        formData.append('file', file);
        // formData.append('upload_url', 'area');

        // Загрузка файла
        // Don't set contentType manually → https://github.com/github/fetch/issues/505#issuecomment-293064470
        const API_URL = '/cityProperty/rest/service-districts/company/img';
        fetch(API_URL, { method: 'POST', body: formData })
          .then(res => res.json())
          .then(res => {
            console.log(res);
            this.$parent.$parent[this.valName || 'imageUrl'] = res.id;
            this.$parent.closeModal();
            // if (res?.files?.length && res?.files[0]?.url) {
            //   this.$parent.$parent[this.valName || 'imageUrl'] = res.files[0].url;
            //   this.$parent.closeModal();
            // }
          });
      }, 'image/jpeg');
    }
  }
};
</script>

<style lang="scss" scoped>
.crop-modal {
  text-align: center;
  width: 618px;
  height: 500px;
  margin: 0 auto;
  &__cropper {
    width: 600px;
    height: 400px;
  }
}

::v-deep .vue-simple-line {
  border-color: var(--rir-rocky);
}

::v-deep .vue-simple-handler {
  background: var(--rir-rocky);
}
::v-deep .vue-advanced-cropper__background {
  background: #fff;
}
::v-deep .vue-advanced-cropper__foreground {
  background: #fff;
}
</style>
